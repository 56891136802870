<template>
    <a :href="navTo" target="_blank">
        <div class="outer">
            <div>{{name}}</div>
        </div>
    </a>
</template>
<script>
export default {
    name: "button-filled",
    props: {
        name: String,
        navTo: String
    },

}
</script>

<style scoped>
.outer {
    width: 150px;
    height: 40px;
    background-color: var(--primary-blue);
    color: white;

    border-radius: 8px;
    font-size: 18px;
    display: flex;
    align-items: center;

    cursor: pointer;
}

.outer div {
    height: fit-content;
    width: fit-content;
    margin: auto;
}
a{
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 768px) {
    .outer {
        width: 120px;
        height: 30px;
        font-size: 12px;
    }
}
</style>