<template>
    <section class="main" id="projects">
        <h1 class="heading">{{"<Projects/>"}}</h1>
        <div class="underline"></div>
        <project-card v-for="project,id in projects.data" :key="id" :project="project.attributes"/>
    </section>
</template>
<script>
import axios from 'axios';
import ProjectCard from './ProjectCard.vue';
const baseUrl = process.env.VUE_APP_API_KEY

const getData = async () => {
    const projectsData = await axios.get(baseUrl+"/projects?populate=images&&sort[0]=Index");
    return projectsData.data
}

export default {

  components: { ProjectCard },
    name:"ProjectPage",
    data(){
        return{
            projects:[]
        }
    },

    async mounted(){
        this.projects = await getData();
    }
}
</script>
<style scoped>
.main{
    position: relative;
    width: 100vw;
    min-height: 90vh;
    z-index: 50;
    background-color:var(--primary-dark);
    margin-bottom: 5rem;
}
.heading{
    width: fit-content;
    margin:5rem auto;
    margin-bottom: 0;
    color: white;
    font-size: 36px;
}
.underline{
    width: 200px;
    margin:0.25rem auto;
    margin-bottom: 5rem;
    height: 2px;
    background-color: white;
}

@media screen and  (max-width: 768px) {
    .main{
        min-height: 100vh;
    }
    .heading{
        margin-top: 5rem;
        font-size: 24px;
    }
    .underline{
        margin-bottom: 2rem;
        width: 150px;
    }
}
    
</style>