<template>
  <div class="card">
    <div class="left">
      <div class="type">{{ temp.type}}</div>
      <div class="name">{{ project.title}}</div>
      <div class="desc">
       {{project.Description}} 
      </div>
      <div class="buttons">
        <ButtonFilled v-if="project.certificate" name="Certificate" :navTo="project.certificate" />
        <ButtonFilled v-if="project.githubUrl" name="GitHub Repo" :navTo="project.githubUrl" />
        <button-outline v-if="project.projectUrl" name="Visit Website" :navTo="project.projectUrl" />
      </div>
    </div>
    <div class="right">
        <img :src="baseFileUrl+project?.images.data[0].attributes.url" alt="project image">
    </div>
  </div>
</template>
<script>
import ButtonFilled from "@/components/buttons/ButtonFill.vue";
import ButtonOutline from "../buttons/ButtonOutline.vue";
export default {
  name: "projectCard",
  components: {
    ButtonFilled,
    ButtonOutline,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseFileUrl: process.env.VUE_APP_FILE_KEY,
      temp: {
        type: "Website",
        name: "Secure Blink",
        Description: "This is front end project based on Vue.js and Vuex. Where i have i worked on basically in api intigrations and settuing up many tools for the functioning of the website",
        image: "",
        githubLink: "",
        siteLink: "",
      },
    };
  },
};
</script>
<style scoped>
.card {
  width: 60vw;
  max-width: 1000px;
  aspect-ratio: 2 / 1.1;
  margin: 5rem auto;

  border: 2px yellow solid;
  border-radius: 70px;

  display: flex;
  justify-content: space-around;
}
.left {
  width: 50%;
  padding-left: 10%;
  padding-top: 6%;
}
.type {
  font-size: 24px;
  min-height:25px ;
}
.name {
  font-size: 40px;
  background-color: var(--primary-blue);
  width: fit-content;
  color: var(--primary-yellow);
  margin-top: 15px;
  border-radius: 10px;
}
.desc {
  margin: 50px 0;
  font-size: 18px;
  min-height: 80px;
}
.buttons{
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
}
.right{
  width: 50%;
  margin: auto;
  padding-right: 20px;
}
.right img{
  width: 100%;
  aspect-ratio: 1/ 1;
}
@media screen and (max-width: 768px) {
  .card{
    width: 100%;
    max-width: 320px;
    height: 640px;

    flex-direction: column-reverse;
    margin: 40px auto;
    padding: 0;

    border-radius: 15px;
  }
  .left{
    width: 100%;
    padding: 1rem;
  }
  .right{
    width: 100%;
    padding: 1rem;
    padding-top: 2rem;
    margin: 0;
  }
  .right img{
    width: calc(100% - 2rem);
    aspect-ratio: 4 / 3;
  }
  .desc{
    font-size: 12px;
    padding-right: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 60px;
  }
}
</style>
