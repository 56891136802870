<template>
    <section class="main" id="contact">
        <h2>Get In Touch</h2>
        <div class="groups">
            <img class="icons" src="@/assets/images/emailLogo.svg" alt="Email">
            <div>
                <a href="mailto:pparthapaul111@outlook.com">pparthapaul111@outlook.com</a>
                <br>
                <a href="mailto:pparthapaul11@gmail.com">pparthapaul11@gmail.com</a>
            </div>
        </div>
        <div class="groups">
            <img class="icons" src="@/assets/images/callIcon.svg" alt="phone">
            <div>
                <a href="tel:+919957970250">+91 9957970250</a>
            </div>
        </div>
        <a :href="baseFileUrl+resume"
            target="_blank">
            <div class="downloads">
                <img class="icons" src="@/assets/images/resumeLogo.svg" alt="linkedin">

                <div>Download Resume</div>
            </div>
        </a>
        <div>
        </div>
    </section>

</template>

<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_API_KEY
const getData = async () => {
    const projectsData = await axios.get(baseUrl+"/contact?populate=resume");
    return projectsData.data
}

export default {
    name: "contact",
    data() {
        return {
            resume:"",
            baseFileUrl: process.env.VUE_APP_FILE_KEY
        };
    },
    async mounted() {
        const temp = await getData();
        this.resume = temp.data.attributes.resume.data.attributes.url;
    }
};
</script>

<style scoped>
.main {
    width: 100vw;
    min-height: 400px;
    overflow: hidden;
    z-index: 50;
    padding-top: 50px;
    background-color: var(--nav-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
}

a {
    display: inline-block;
    text-decoration: none;
    color: white;
}

a:hover {
    color: var(--primary-yellow);
}

.icons {
    width: 50px;
    margin-right: 10px;
}

.groups {
    width: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.groups>div {
    margin-left: 30px;
    margin-right: auto;
}

.downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 300px;
}

@media screen and (max-width:786px) {
    .main{
        font-size: 14px;
    }
    .groups{
        width: 300px;
    }
    .groups>div {
    margin-left: 10px;
    margin-right: auto;
    }
    .icons{
        width: 40px;
    }
    
}
</style>